export const homeObjOne = {
    id: 'about',
    lightBg: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Place Holder Spot',
    headline: 'Title spot',
    description: 'Cute Crypto was born as a passion project for our team incorporating our love for art, fashion, and NFTs. We wanted to bring our pastels, J-fashion, and other cuteness to the Ethereum blockchain.  With our NFT we want to give holders the ability to personalize their cuties with the ability to rename them and equip extra accessories. The collection consists of 10,000 hand-drawn randomly generated Cuties. The cuties are composed of 200 possible traits across eight different properties at launch. We can’t wait for you to join us and show off your cuteness in the Metaverse.',
    buttonLabel: 'Get Started',
    imgStart: false,
    img: require('../../images/CircleDisplay.png').default,
    alt: 'Failed to load image',
    dark: true,
    primary: true,
    darkText: true,
};